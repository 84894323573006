'use client';

import { BtnColorEnum, BtnSizeEnum, BtnVariantEnum } from '@features/api/client';
import { Button } from '@features/webshop/shared/ui/Button';
import { Result } from 'antd';
import { $path } from 'next-typesafe-url';
import Link from 'next/link';

export default function Error({ reset, error }: { reset: () => void; error: Error & { digest?: string } }) {
	return (
		<Result
			status={undefined}
			title="Oops! Nešto je pošlo po krivu."
			subTitle="Došlo je do greške. Pokušaj ponovno kroz nekoliko minuta, a mi ćemo u međuvremenu učiniti sve što možemo kako bi u najkraćem mogućem roku popravili problem."
			extra={
				<div className="flex flex-col gap-4">
					<div className="flex flex-wrap justify-center gap-3">
						<Button
							size={BtnSizeEnum.Medium}
							color={BtnColorEnum.Blue}
							variant={BtnVariantEnum.Outline}
							onClick={reset}>
							Probaj ponovno
						</Button>
						<Link href={$path({ route: '/[[...path]]', routeParams: { path: undefined } })}>
							<Button
								size={BtnSizeEnum.Medium}
								color={BtnColorEnum.Blue}
								variant={BtnVariantEnum.Primary}>
								Idi na početnu
							</Button>
						</Link>
					</div>
				</div>
			}
		/>
	);
}
